import { getShops } from "@api/Shops"
import { Shop } from "cs.node.utils/csapi/provisioning"
import { getLocalStorageItem, setLocalStorageItem } from "@utils/LocalStorage"
import MD5 from "md5";

enum CloudStoreShopIdEnum {
    _24S                = '6548e520d5340cb90a2c5e27',
    AmazonEu            = '599ed8fa4344a5d7809a13c4',
    Amuze               = '620237cc82be87ce6bcd45d8',
    Cettire             = '66058b86c6dfb244bf294fed',
    Decathlon           = '64357a09a2b9cbb3688bdfe4',
    Ebay                = '53ea6ced777327762af14432',
    Farfetch            = '5b19010ce2e95d19cae7d18c',
    GaleriesLafayette   = "65f9500cb8c7c6fc82c8a24c",
    Italist             = '6490554374c666de2b782869',
    Miinto              = '63298739e0e5f68e0fe4e19e',
    PersonalShop        = '55b62dd274005d12b400e236',
    /* Mirakl            = '61a0ffc51e4cb2cb355c36b4',*/
    Savait              = '63936f79a8dffeed12025013',
    Shopify             = '59e9c29882b58204db50abed',
    ShopifyPos          = '63d0d6d52a49cee3b0a450a9',
    Winkelstraat        = '6579b638dc84dcadd3f83fb1',
    Yoox                = "61a0ffc51e4cb2cb355c36b4",
    Zalando             = '633bde07d19a6256bdc8b6eb',
}

export type CloudStoreShop = {
    id: CloudStoreShopIdEnum
    name: string
    isChannel?: boolean,
    creationEnabled?: boolean
    componentName?: string
    icons: {
        short: string
        extended: string
    },
}
const cloudStoreShops:CloudStoreShop[] = [
    { id: CloudStoreShopIdEnum._24S,                name: "24S",                    isChannel: false,   creationEnabled: true,  componentName: "_24S",              icons: { short: '24SShort',             extended: '24SExtended'             }},
    { id: CloudStoreShopIdEnum.AmazonEu,            name: "Amazon EU",              isChannel: true,    creationEnabled: true,  componentName: "Amazon",            icons: { short: 'AmazonShort',          extended: 'AmazonExtended'          }},
    { id: CloudStoreShopIdEnum.Amuze,               name: "Amuze Inc",              isChannel: false,   creationEnabled: false, componentName: undefined,           icons: { short: 'AmuzeShort',           extended: 'AmuzeExtended'           }},
    { id: CloudStoreShopIdEnum.Cettire,             name: "Cettire",                isChannel: false,   creationEnabled: true,  componentName: "Cettire",           icons: { short: 'CettireShort',         extended: 'CettireExtended'         }},
    { id: CloudStoreShopIdEnum.PersonalShop,        name: "CS Personal Shop",       isChannel: false,   creationEnabled: true,  componentName: "PersonalShop",      icons: { short: 'CSPersonalShopShort',  extended: 'CSPersonalShopExtended'  }},
    { id: CloudStoreShopIdEnum.Decathlon,           name: "Decathlon",              isChannel: false,   creationEnabled: true,  componentName:"Decathlon",          icons: { short: 'DecathlonShort',       extended: 'DecathlonExtended'       }},
    { id: CloudStoreShopIdEnum.Ebay,                name: "Ebay Italia",            isChannel: false,   creationEnabled: false, componentName: undefined,           icons: { short: 'EbayItaliaShort',      extended: 'EbayItaliaExtended'      }},
    { id: CloudStoreShopIdEnum.Farfetch,            name: "Farfetch",               isChannel: false,   creationEnabled: true,  componentName: "Farfetch",          icons: { short: 'FarfetchShort',        extended: 'FarfetchExtended'        }},
    { id: CloudStoreShopIdEnum.Italist,             name: "Italist",                isChannel: false,   creationEnabled: true,  componentName:"Italist",            icons: { short: 'ItalistShort',         extended: 'ItalistExtended'         }},
    { id: CloudStoreShopIdEnum.GaleriesLafayette,   name: "Galleries Lafayette",    isChannel: false,   creationEnabled: true,  componentName:"GalleriesLafayette", icons: { short: 'GLFShort',             extended: 'GLFExtended'             }},
    { id: CloudStoreShopIdEnum.Miinto,              name: "Miinto",                 isChannel: false,   creationEnabled: true,  componentName: "Miinto",            icons: { short: 'MiintoShort',          extended: 'MiintoExtended'          }},
    { id: CloudStoreShopIdEnum.Yoox,                name: "Yoox",                   isChannel: false,   creationEnabled: true,  componentName:"Yoox",               icons: { short: 'YooxShort',            extended: 'YooxExtended'            }},
    { id: CloudStoreShopIdEnum.Savait,              name: "Savait",                 isChannel: false,   creationEnabled: true,  componentName: "Savait",            icons: { short: 'SavaitShort',          extended: 'SavaitExtended'          }},
    { id: CloudStoreShopIdEnum.Shopify,             name: "Shopify",                isChannel: false,   creationEnabled: false, componentName: undefined,           icons: { short: 'ShopifyShort',         extended: 'ShopifyExtended'         }},
    { id: CloudStoreShopIdEnum.ShopifyPos,          name: "Shopify POS",            isChannel: false,   creationEnabled: false, componentName: undefined,           icons: { short: 'ShopifyPOSShort',      extended: 'ShopifyPOSExtended'      }},
    { id: CloudStoreShopIdEnum.Winkelstraat,        name: "Winkelstraat",           isChannel: false,   creationEnabled: true,  componentName: "Winkelstraat",      icons: { short: 'WinkelstraatShort',    extended: 'WinkelstraatExtended'    }},
    { id: CloudStoreShopIdEnum.Zalando,             name: "Zalando",                isChannel: false,   creationEnabled: false, componentName: undefined,           icons: { short: 'ZalandoShort',         extended: 'ZalandoExtended'         }},
]

const shopStatusThemes = [
    {status: "CONFIRMED", bg:"success", icon:"Success"},
    {status: "ERROR", bg:"danger", icon:"Error"},
    {status: "AUTH_ERROR", bg:"danger", icon:"Error"},
    {status: "PENDING", bg:"warning", icon:"Running"},
]

const getCloudStoreShop = (id?:CloudStoreShopIdEnum | string) =>{
    if(!id) return undefined
    
    return cloudStoreShops.find(cloudStoreShop=>cloudStoreShop.id===id)
}

/**
 * Get from local storage the list of user shops if exists, else load them from the server
 * If forceRefresh is true, always reload the shops
 * @returns the list of user shops
 */
const getUserShops = async (options?: {forceRefresh:boolean})=>{
    const { forceRefresh=false } = options ?? {}
    let userShops = getLocalStorageItem('user.shops') as Shop[]
    if(forceRefresh || !userShops) {
        // fetch shops from server
        const shopsResponse = await getShops()
        userShops = shopsResponse.content ?? []
        setLocalStorageItem('user.shops', userShops)
    }

    return userShops
}

const getShopsFromIds = (shopIds:string[] = []) => {
    const userShops = getLocalStorageItem('user.shops') as Shop[]
    if(!userShops) { return [] }
    return userShops.filter(shop=>shopIds.includes(shop._id?.$oid!))
}

const getShopFromId = (shopId:string) => {
    const userShops = getLocalStorageItem('user.shops') as Shop[]
    if(!userShops) { return undefined }
    return userShops.find(shop=>shop._id?.$oid === shopId)
}

const getShopFromName = (shopName:string) => {
    const userShops = getLocalStorageItem('user.shops') as Shop[]
    if(!userShops) { return undefined }
    return userShops.find(shop=>shop.name === shopName)
}

const getShopFromIdForcedLoad = async (shopId: string) => {
    const userShops = await getUserShops()
    return userShops.find(shop => shop._id?.$oid === shopId)
}

const getShopsFromCsShopIds =  (cs_shop_id: string) => {
    let userShops = getLocalStorageItem('user.shops') as Shop[]
    return userShops.filter(shop => shop.cs_shop_id?.$oid === cs_shop_id)
}

const generateShopToken =  (cs_shop_id: string) => {
    const dataToHash =  new Date().toISOString() + cs_shop_id;
    return MD5(dataToHash).toString();

}

const getShopStatus = (status: string)=>{
    let bg = "light"
    let icon = null
    const shopStatusTheme = shopStatusThemes.find(theme=>theme.status===status)
    if(shopStatusTheme) {
        bg = shopStatusTheme.bg
        icon = shopStatusTheme.icon
    }

    return {bg, icon}
}



export { cloudStoreShops, getCloudStoreShop, CloudStoreShopIdEnum, getUserShops, getShopsFromIds, getShopStatus, getShopFromId, getShopFromIdForcedLoad, generateShopToken, getShopsFromCsShopIds, getShopFromName }