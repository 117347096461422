import { EntityListResponseModule, EntitySingleResponseModuleResponseModuleB2B, EntitySingleResponseModuleResponseModuleBusinessConditions, EntitySingleResponseModuleResponseModuleERP, EntitySingleResponseModuleResponseModuleFinance, EntitySingleResponseModuleResponseModuleFxFF, EntitySingleResponseModuleResponseModuleMarketplaceSavait, EntitySingleResponseModuleResponseModuleNetSuite, EntitySingleResponseModuleResponseModulePOS, EntitySingleResponseModuleResponseModuleWarehouses, ModuleB2B, ModuleBusinessConditions, ModuleERP, ModuleFinance, ModuleFxFF, ModuleMarketplaceSavait, ModuleNetSuite, ModulePOS, ModuleWarehouses } from "cs.node.utils/csapi/provisioning"
import axios, { AxiosResponse } from "axios"
import { checkSessionExpired } from "./Utils"
import { moduleDescriptors } from '../Utils/ModulesUtils';

const getNetSuiteInfo  = async () =>{
    try {
      const response:AxiosResponse<EntitySingleResponseModuleResponseModuleNetSuite> = await axios.get(`api/modules/getNetSuite`)
      return response.data
    } catch(error: any) {
      if(error.response?.status === 404) {
          return undefined
      }
      checkSessionExpired(error)
      throw error
  }
}

const getModules  = async () =>{
  try {
    const response:AxiosResponse<EntityListResponseModule> = await axios.get(`api/modules`)
    return response.data
  } catch(error: any) {
    checkSessionExpired(error)
    throw error
}
}


type ModuleResponse = 
  | EntitySingleResponseModuleResponseModuleB2B 
  | EntitySingleResponseModuleResponseModuleBusinessConditions 
  | EntitySingleResponseModuleResponseModuleERP 
  | EntitySingleResponseModuleResponseModuleFinance 
  | EntitySingleResponseModuleResponseModuleFxFF 
  | EntitySingleResponseModuleResponseModuleMarketplaceSavait 
  | EntitySingleResponseModuleResponseModuleNetSuite 
  | EntitySingleResponseModuleResponseModulePOS 
  | EntitySingleResponseModuleResponseModuleWarehouses

const getModuleDetails = async (id: string) =>{
  try {
    const response:AxiosResponse<ModuleResponse> = await axios.get(`api/modules/getModules/${id}`)
    return response.data
  } catch(error: any) {
    if(error.response?.status === 404) {
        return undefined
    }
    checkSessionExpired(error)
    throw error
  }

}

type ModuleT = 
  | ModuleB2B 
  | ModuleBusinessConditions 
  | ModuleERP 
  | ModuleFinance 
  | ModuleFxFF 
  | ModuleMarketplaceSavait 
  | ModuleNetSuite 
  | ModulePOS 
  | ModuleWarehouses

  type ModuleUpdateRes =
  | EntitySingleResponseModuleResponseModuleB2B
  | EntitySingleResponseModuleResponseModuleBusinessConditions
  | EntitySingleResponseModuleResponseModuleERP
  | EntitySingleResponseModuleResponseModuleFinance
  | EntitySingleResponseModuleResponseModuleFxFF
  | EntitySingleResponseModuleResponseModuleMarketplaceSavait
  | EntitySingleResponseModuleResponseModuleNetSuite
  | EntitySingleResponseModuleResponseModulePOS
  | EntitySingleResponseModuleResponseModuleWarehouses; 

const updateModuleDetails = async (id: string, module: ModuleT)  =>{
  try {
    const params= {id: id, module:module }

    const response:AxiosResponse<ModuleUpdateRes> = await axios.put(`api/modules/updateModule`,{params})
    return response.data
  } catch(error: any) {
    if(error.response?.status === 404) {
        return undefined
    }
    checkSessionExpired(error)
    throw error
  }

}


export {getNetSuiteInfo, getModules, getModuleDetails, updateModuleDetails}
