import React, { useEffect, lazy, Suspense } from 'react'
import './App.scss'

import { Route, Routes } from 'react-router-dom'
import Protected from '@components/Protected'
import { AuthContext } from '@providers/AuthProvider'
import { FiltersProvider } from '@providers/FiltersProvider'

import { CategoryProvider } from '@components/CategoryList/CategoryContext'
import { ModuleIdEnum } from 'cs.node.utils/csapi/provisioning'
import { QueueFavoritesProvider } from '@components/Queue/QueueFavoritesContext'
import QueueDetails from '@pages/Queue/QueueDetails'
import Customer from '@pages/Customers/Customer'
import ModuleDetailProxy from '@pages/Modules/ModuleDetailsPages/ModuleDetailProxy'

const Login = lazy(()=>import("@pages/Login"))
const Home = lazy(()=>import("@pages/Home/Home"))
const Logout = lazy(()=>import("@pages/Logout"))
const AccessDenied = lazy(()=>import("@pages/Errors/AccessDenied"))

const Shops = lazy(()=>import("@pages/Shops/Shops"))
const ShopsWarehousesBindings = lazy(()=>import("@pages/ShopsWarehousesBindings/ShopsWarehousesBindings"))
const CreateShop = lazy(()=>import("@pages/Shops/CreateShop"))
const CreateShopChannel = lazy(()=>import("@pages/Shops/CreateShopChannel"))
const EditShopChannel = lazy(()=>import("@pages/Shops/EditShopChannel"))
const EditShop = lazy(()=>import("@pages/Shops/EditShop"))
const Warehouses = lazy(()=>import("@pages/Warehouses"))
const Catalog = lazy(()=>import("@pages/Catalog/Catalog"))
const Item = lazy(()=>import("@pages/Item/Item"))
const Shipments = lazy(()=>import("@pages/Shipments"))
const BindingsShops = lazy(()=>import("@pages/Bindings/BindingsShops"))
const BindingsAttributes = lazy(()=>import("@pages/Bindings/BindingsAttributes"))
const BindingsRule = lazy(()=>import("@pages/Bindings/BindingsRule"))
const Orders = lazy(()=>import("@pages/Orders/Orders"))
const Order = lazy(()=>import("@pages/Order/Order"))
const AvailabilityAdjustments = lazy(()=>import("@pages/AvailabilityAdjustments"))
const WarehousesMovements = lazy(()=>import("@pages/WarehousesMovements"))
const Category = lazy(()=>import("@pages/Category/Category"))
const Returns = lazy(()=>import("@pages/Returns/Returns"))
const Return = lazy(()=>import("@pages/Returns/Return"))
const BusinessConditions = lazy(()=>import("@pages/BusinessConditions/BusinessConditions"))
const EditBusinessCondition = lazy(()=>import("@pages/BusinessConditions/EditBusinessCondition"))
const Logs = lazy(()=>import("@pages/Logs/Logs"))
const Packaging = lazy(()=>import("@pages/Packaging/Packaging"))
const PackagingOrders = lazy(()=>import("@pages/Packaging/PackagingOrders"))
const LogsSettings = lazy(()=>import("@pages/LogsSettings/LogsSettings"))
const Reservations = lazy(()=>import("@pages/Reservations/Reservations"))
const Queues = lazy(()=>import("@pages/Queues/Queues"))
const Queue = lazy(() => import("@pages/Queue/Queue"))
const ListingExclusionRules = lazy(()=>import("@pages/ListingExclusionRules/ListingExclusionRules"))
const EditListingExclusionRule = lazy(()=>import("@pages/ListingExclusionRules/EditListingExclusionRule"))
const Customers = lazy(()=>import("@pages/Customers/Customers"))
const Modules = lazy(()=>import("@pages/Modules/Modules"))


function App() {

  const auth = React.useContext(AuthContext)

  const setLogged = async() =>{
      await auth.setUserData()
  }

  const setEnvironment = async ()=>{
    await auth.setEnvironment()
    return
  }

  useEffect(() => {
      setLogged()
      setEnvironment()
  },[])

  return (
    <Suspense fallback={<></>}>
      <Routes>
          <Route path="/" element={<Protected roles={["BASE", "SUPPORT", "PACKAGER"]}><Home /></Protected>} />
          <Route path="/shops" element={<Protected><Shops /></Protected>} />
          <Route path="/shops/new/channel/:cs_shop_id" element={<Protected><CreateShopChannel /></Protected>} />
          <Route path="/shops/new/:cs_shop_id" element={<Protected><CreateShop /></Protected>} />
          <Route path="/shops/channel/:cs_shop_id" element={<Protected><EditShopChannel /></Protected>} />
          <Route path="/shops/warehousesbindings" element={<Protected><ShopsWarehousesBindings /></Protected>} />
          <Route path="/shops/:cs_shop_id/:shop_id" element={<Protected><EditShop /></Protected>} />
          <Route path="/catalog" element={<Protected><FiltersProvider key={"filtersProvider_catalog"}><Catalog /></FiltersProvider></Protected>} />
          <Route path="/catalog/:sku_parent" element={<Protected><Item /></Protected>} />
          <Route path="/listing/bindings" element={<Protected><BindingsShops /></Protected>} />
          <Route path="/listing/bindings/:shop_id" element={<Protected><BindingsAttributes /></Protected>} />
          <Route path="/listing/bindings/:shop_id/attribute/:attribute/rule/:rule_id" element={<Protected><BindingsRule /></Protected>} />
          <Route path="/orders" element={<Protected><FiltersProvider key={"filtersProvider_orders"}><Orders/></FiltersProvider></Protected>} />
          <Route path="/orders/:id" element={<Protected><Order /></Protected>} />
          <Route path="/shipments" element={<Protected><FiltersProvider key={"filtersProvider_shipments"}><Shipments/></FiltersProvider></Protected>} />
          <Route path="/availability-adjustments" element={<Protected><FiltersProvider key={"filtersProvider_availability_adjustments"}><AvailabilityAdjustments></AvailabilityAdjustments></FiltersProvider></Protected>} />
          <Route path="/returns" element={<Protected><FiltersProvider key={"filtersProvider_returns"}><Returns/></FiltersProvider></Protected>} />
          <Route path="/returns/:id" element={<Protected><Return /></Protected>} />
          <Route path="/warehouses" element={<Protected modules={[ModuleIdEnum.Warehouses]}><Warehouses /></Protected>} />
          <Route path="/warehousesmovements" element={<Protected modules={[ModuleIdEnum.Warehouses]}><FiltersProvider key={"filtersProvider_warehouses_movements"}><WarehousesMovements/></FiltersProvider></Protected>} />
          <Route path="/category" element={<Protected><CategoryProvider><Category/></CategoryProvider></Protected>} />
          <Route path="/businessconditions" element={<Protected modules={[ModuleIdEnum.BusinessConditions]}><BusinessConditions /></Protected>} />
          <Route path="/businessconditions/:id" element={<Protected modules={[ModuleIdEnum.BusinessConditions]}><EditBusinessCondition /></Protected>} />
          <Route path="/logs" element={<Protected><FiltersProvider key={"filtersProvider_logs"}><Logs/></FiltersProvider></Protected>} />
          <Route path="/packaging" element={<Protected roles={["SUPPORT", "PACKAGER"]} modules={[ModuleIdEnum.Warehouses]}><Packaging /></Protected>} />
          <Route path="/packaging/orders" element={<Protected roles={["SUPPORT", "PACKAGER"]} modules={[ModuleIdEnum.Warehouses]}><FiltersProvider key={"filtersProvider_packaging_orders"}><PackagingOrders /></FiltersProvider></Protected>} />
          <Route path="/logs/settings" element={<Protected><LogsSettings/></Protected>} />
          <Route path="/reservations" element={<Protected modules={[ModuleIdEnum.Warehouses]}><FiltersProvider key={"filtersProvider_reservations"}><Reservations/></FiltersProvider></Protected>} />
          <Route path="/queues" element={<Protected><QueueFavoritesProvider><Queues/></QueueFavoritesProvider></Protected>} />
          <Route path="/queues/:id" element={<Protected><Queue/></Protected>} />
          <Route path="/queues/:id/:selected_queue_item_Id" element={<Protected><QueueDetails/></Protected>} />
          <Route path="/listing/exclusion-rules" element={<Protected><ListingExclusionRules /></Protected>} />
          <Route path="/listing/exclusion-rules/:id" element={<Protected><EditListingExclusionRule /></Protected>} />
          <Route path="/customers/" element={<Protected><Customers/></Protected>} />
          <Route path="/customers/:id" element={<Protected><FiltersProvider key={"filtersProvider_customers"}><Customer/></FiltersProvider></Protected>} />
          <Route path="/modules/" element={<Protected roles={["SUPPORT"]}><Modules/></Protected>} />
          <Route path="/modules/:id" element={<Protected roles={["SUPPORT"]}><ModuleDetailProxy/></Protected>} />

          <Route path="/accessdenied" element={<AccessDenied />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
      </Routes>
    </Suspense>
  )
}

export default App;
