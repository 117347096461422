import { BusinessConditionStatus, EntityListResponseBusinessConditionViewSummary, BusinessConditionStatusEnum, EntityListResponseCustomer, EntityListResponseShop, EntitySingleResponseBusinessConditionStatus, EntitySingleResponseBusinessCondition, EntitySingleResponseShopBusinessConditionReference, EntitySingleResponseCustomerBusinessConditionReference, BusinessCondition, EntitySingleResponseObjectId, EntityListResponseCustomerBusinessConditionReference, EntityListResponseBusinessCondition } from "cs.node.utils/csapi/provisioning"
import { BusinessConditionsShopsAssociationsArgs, BusinessConditionsCustomersAssociationsArgs } from '../../../src/interfaces/BusinessConditions'
import axios, { AxiosResponse } from "axios"
import qs from "qs"
import { checkSessionExpired } from "./Utils"
import { UploadBusinessConditionsExceptionsResponse } from "@interfaces/BusinessConditions"

const getBusinessConditionsStatus = async ():Promise<BusinessConditionStatus> =>{
    try {
        const response:AxiosResponse<BusinessConditionStatus,any> = await axios.get('api/businessconditions/status')
        return response.data
    } catch(error:any) {
        checkSessionExpired(error)
        throw error
    }
}

const getBusinessConditions = async (status: BusinessConditionStatusEnum[]):Promise<EntityListResponseBusinessConditionViewSummary> =>{
    try {
        const response:AxiosResponse<EntityListResponseBusinessConditionViewSummary,any> = await axios.get('api/businessconditions', {
            params: {status}
        })
        return response.data
    } catch(error:any) {
        checkSessionExpired(error)
        throw error
    }
}

const getBusinessConditionsByRootId = async (rootId: string):Promise<EntityListResponseBusinessCondition> =>{
    try {
        const response:AxiosResponse<EntityListResponseBusinessCondition,any> = await axios.get(`api/businessconditions/by-root-id/${rootId}`)
        return response.data
    } catch(error:any) {
        checkSessionExpired(error)
        throw error
    }
}

const getBusinessConditionsShopsAssociations = async(params: BusinessConditionsShopsAssociationsArgs) =>{
    try {
        const response:AxiosResponse<EntityListResponseShop,any> = await axios.get('api/businessconditions/associations/shops', {
            params
        })
        return response.data
    } catch(error:any) {
        checkSessionExpired(error)
        throw error
    }
}

const getBusinessConditionsCustomersAssociations = async(params: BusinessConditionsCustomersAssociationsArgs) =>{
    try {
        const response:AxiosResponse<EntityListResponseCustomer,any> = await axios.get('api/businessconditions/associations/customers', {
            params
        })
        return response.data
    } catch(error:any) {
        checkSessionExpired(error)
        throw error
    }
}

const getCustomerBusinessConditions = async(id:string) =>{
    try {
        const response:AxiosResponse<EntityListResponseCustomerBusinessConditionReference,any> = await axios.get(`api/businessconditions/customer/${id}`)
        return response.data
    } catch(error:any) {
        checkSessionExpired(error)
        throw error
    }
}

const updateBusinessConditionStatus = async (id: string, status: BusinessConditionStatusEnum):Promise<EntitySingleResponseBusinessConditionStatus> =>{
    try {
        const response:AxiosResponse<EntitySingleResponseBusinessConditionStatus,any> = await axios.put(`api/businessconditions/${id}/status`, {status})
        return response.data
    } catch(error:any) {
        checkSessionExpired(error)
        throw error
    }
}

const cancelBusinessCondition = async (id: string):Promise<EntitySingleResponseBusinessCondition> =>{
    try {
        const response:AxiosResponse<EntitySingleResponseBusinessCondition,any> = await axios.delete(`api/businessconditions/${id}`)
        return response.data
    } catch(error:any) {
        checkSessionExpired(error)
        throw error
    }
}

const getBusinessCondition = async (id: string):Promise<EntitySingleResponseBusinessCondition> =>{
    try {
        const response:AxiosResponse<EntitySingleResponseBusinessCondition,any> = await axios.get(`api/businessconditions/${id}`)
        return response.data
    } catch(error:any) {
        checkSessionExpired(error)
        throw error
    }
}

const addBusinessConditionShopAssociations = async(bc_id: string, shop_ids: string[]):Promise<unknown> =>{
    try {
        const response:AxiosResponse<unknown,any> = await axios.post(`api/businessconditions/associations/shops`, {bc_id, shop_ids})
        return response.data
    } catch(error:any) {
        checkSessionExpired(error)
        throw error
    }
}

const deleteBusinessConditionShopAssociation = async(bc_id: string, shop_id: string):Promise<EntitySingleResponseShopBusinessConditionReference> =>{
    try {
        const response:AxiosResponse<EntitySingleResponseShopBusinessConditionReference,any> = await axios.delete(`api/businessconditions/${bc_id}/associations/shops/${shop_id}`)
        return response.data
    } catch(error:any) {
        checkSessionExpired(error)
        throw error
    }
}

const addBusinessConditionCustomerAssociations = async(bc_id: string, customer_ids: string[]):Promise<unknown> =>{
    try {
        const response:AxiosResponse<unknown,any> = await axios.post(`api/businessconditions/associations/customers`, {bc_id, customer_ids})
        return response.data
    } catch(error:any) {
        checkSessionExpired(error)
        throw error
    }
}

const deleteBusinessConditionCustomerAssociation = async(bc_id: string, customer_id: string):Promise<EntitySingleResponseCustomerBusinessConditionReference> =>{
    try {
        const response:AxiosResponse<EntitySingleResponseCustomerBusinessConditionReference,any> = await axios.delete(`api/businessconditions/${bc_id}/associations/customers/${customer_id}`)
        return response.data
    } catch(error:any) {
        checkSessionExpired(error)
        throw error
    }
}

const createBusinessCondition = async(businessCondition: BusinessCondition) =>{
    try {
        const response:AxiosResponse<EntitySingleResponseBusinessCondition,any> = await axios.post(`api/businessconditions/`, businessCondition)
        return response.data
    } catch(error:any) {
        checkSessionExpired(error)
        throw error
    }
}

const updateBusinessCondition = async(id: string, businessCondition: BusinessCondition) =>{
    try {
        const response:AxiosResponse<EntitySingleResponseBusinessCondition,any> = await axios.put(`api/businessconditions/${id}`, businessCondition)
        return response.data
    } catch(error:any) {
        checkSessionExpired(error)
        throw error
    }
}

const applyBusinessConditions = async()=>{
    try {
        const response:AxiosResponse<EntitySingleResponseObjectId,any> = await axios.post(`api/businessconditions/apply`)
        return response.data
    } catch(error:any) {
        checkSessionExpired(error)
        throw error
    }
}

const exportExceptionsTemplate = async()=>{
    try{
        const response:AxiosResponse<any,any> = await axios.get('api/businessconditions/excel/exceptions-template', {
            responseType: 'blob'
        })
        return response.data
    } catch(error:any) {
        checkSessionExpired(error)
        throw error
    }
}

const exportExceptions = async(id:string)=>{
    try{
        const response:AxiosResponse<any,any> = await axios.get(`api/businessconditions/${id}/excel/exceptions`, {
            responseType: 'blob'
        })
        return response.data
    } catch(error:any) {
        checkSessionExpired(error)
        throw error
    }
}

const uploadBusinessConditionExceptions = async(data:FormData) =>{
    try {
        const response:AxiosResponse<UploadBusinessConditionsExceptionsResponse,any> = await axios.post(`api/businessconditions/excel/exceptions`, data)
        return response.data
    } catch(error:any) {
        throw error
    }
}



export { 
    getBusinessConditionsStatus,
    getBusinessConditions,
    getBusinessConditionsByRootId,
    getBusinessConditionsShopsAssociations,
    getBusinessConditionsCustomersAssociations,
    updateBusinessConditionStatus,
    cancelBusinessCondition,
    getBusinessCondition,
    addBusinessConditionShopAssociations,
    deleteBusinessConditionShopAssociation,
    addBusinessConditionCustomerAssociations,
    deleteBusinessConditionCustomerAssociation,
    createBusinessCondition,
    updateBusinessCondition,
    applyBusinessConditions,
    exportExceptionsTemplate,
    exportExceptions,
    uploadBusinessConditionExceptions,
    getCustomerBusinessConditions
}