import axios, { AxiosError, AxiosResponse } from "axios"
import { checkSessionExpired } from "@api/Utils"
import { Category, EntityListResponseCategory } from 'cs.node.utils/csapi/provisioning'

const getCategories = async ():Promise<EntityListResponseCategory> =>{
    try {
        const response:AxiosResponse<EntityListResponseCategory,any> = await axios.get('api/categories')
        return response.data
    } catch(error:any) {
        checkSessionExpired(error)
        throw error
    }
}

const updateCategory  = async (categoryId:String, category: Category) =>{
    try {
        const response:AxiosResponse<EntityListResponseCategory,any> = await axios.put(`api/categories/${categoryId}/update`,{category})
        return response.data
    } catch(error:any) {
        checkSessionExpired(error)
        throw error
    }
}

const deleteCategory  = async (categoryId:String) =>{
    try {
        const response:AxiosResponse<EntityListResponseCategory,any> = await axios.delete(`api/categories/${categoryId}/delete`)
        return response.data
    } catch(error:any) {
        checkSessionExpired(error)
        throw error
    }
}

const createCategory  = async (category: Category) =>{
    try {
        const response:AxiosResponse<EntityListResponseCategory,any> = await axios.post(`api/categories/`, {category})
        return response.data
    } catch(error:any) {
        checkSessionExpired(error)
        throw error
    }
}




export { getCategories , updateCategory , deleteCategory, createCategory}