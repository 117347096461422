// import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import axios from 'axios'
import { AuthProvider } from '@providers/AuthProvider';
import { ConfigurationProvider } from '@providers/ConfigurationProvider';
import "bootstrap-icons/font/bootstrap-icons.css";

axios.defaults.headers.common['cs-environment-id'] = 'cs-appweb'
console.log(`%cVersion: %c${process.env.APP_VERSION}`, "font-size: 20px", "color: #1b84ff; font-size: 20px; font-weight: bold;")
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.warn = () => {};
  console.error = () => {};
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <AuthProvider>
    <ConfigurationProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ConfigurationProvider>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
