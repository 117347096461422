import axios, { AxiosResponse } from "axios"
import { checkSessionExpired } from "@api/Utils"
import { NotFoundItemInArray } from '../../../src/interfaces/Items'
import { EntityListResponseItem, EntityListResponseItemSummary, EntityListResponseObject, EntitySingleResponseItem, Item } from 'cs.node.utils/csapi/provisioning'
import { ItemsAnalysis } from "cs.node.utils/ItemUtils"
import { EntityListResponseItemAvailabilityAdjustmentViewSummary, EntityListResponseWarehouseMovementViewSummary, EntitySingleResponseItemMetadata, EntitySingleResponseListItemBusinessCondition } from "cs.node.utils/csapi/provisioning"

const getMetadata = async (field:string):Promise<EntityListResponseObject> =>{
    try {
        const response:AxiosResponse<EntityListResponseObject,any> = await axios.get(`api/items/metadata/${field}`)
        return response.data
    } catch(error:any) {
        checkSessionExpired(error)
        throw error
    }
}


export interface MOCKCatalogItem {
    mnf_code?: string,
    brand?: string,
    sku_parents?: MOCKCatalogItem[],
    images?: string[],
    sku_parent?: string,
    skus?: MOCKCatalogItem[],
    sku?: string,
    name?: string,
    description?: string,
    size?: string
}

const getItems = async(controller: AbortController, pageIndex?: number, pageSize?: number, sort?: string, searchTerm?: string)=>{
    try {
        const response:AxiosResponse<EntityListResponseItem,any> = await axios.get(`api/items`, {
            params: { pageIndex, pageSize, sort, searchTerm },
            signal: controller.signal
        })
        return response.data
    } catch(error:any) {
        checkSessionExpired(error)
        throw error
    }
}

const getItemsFromCodeList = async (codeList: string[], type: string) =>{
    try {
        const response:AxiosResponse<EntityListResponseItemSummary,any> = await axios.post(`api/items/listbyCodeList?type=${type}`, codeList)
        return response.data
    } catch(error:any) {
        checkSessionExpired(error)
        throw error
    }
}

const getItemsbyParentSku = async (skuParent: string) =>{
    try {
        const response:AxiosResponse<EntityListResponseItem, any> =  await axios.get(`api/items/getItemsByParentSKU/${skuParent}`)
        return response.data

    } catch (error: any) {
        checkSessionExpired(error)
        throw error
    }

}

const getItemBySku = async (sku: string) =>{
    try {
        const response:AxiosResponse<EntitySingleResponseItem, any> =  await axios.get(`api/items/getItemBySKU/${sku}`)
        return response.data

    } catch (error: any) {
        checkSessionExpired(error)
        throw error
    }

}

const getItemById = async(id: string)=>{
    try {
        const response:AxiosResponse<EntitySingleResponseItem, any> =  await axios.get(`api/items/getItemById/${id}`)
        return response.data

    } catch (error: any) {
        checkSessionExpired(error)
        throw error
    }
}

const getItemsByIdList = async(idList: string[])=>{
    try {
        const response:AxiosResponse<Array<Item | NotFoundItemInArray>, any> =  await axios.post(`api/items/getItemsByIdList`, idList)
        return response.data

    } catch (error: any) {
        checkSessionExpired(error)
        throw error
    }
}

const analyzeItems = async(items: Item[])=>{
    try {
        const response:AxiosResponse<ItemsAnalysis, any> =  await axios.post(`api/items/analyze`, {items})
        return response.data
    } catch (error: any) {
        checkSessionExpired(error)
        throw error
    }
}

const patchItems = async(ids: string[], body: Item)=>{
    try {
        const response:AxiosResponse<PromiseSettledResult<Item>[], any> =  await axios.patch(`api/items/bulk`, {ids, body})
        return response.data
    } catch (error: any) {
        checkSessionExpired(error)
        throw error
    }
}

const addImageToItems = async(data:FormData) =>{
    try {
        const response = await axios.post('api/items/image', data)
        return response.data
    } catch(error:any) {
        throw error
    }
}

const getItemMetadata = async(id: string)=>{
    try {
        const response:AxiosResponse<EntitySingleResponseItemMetadata, any> =  await axios.get(`api/items/${id}/metadata`)
        return response.data

    } catch (error: any) {
        checkSessionExpired(error)
        throw error
    }
}

const getItemAvailabilityAdjustments = async(controller: AbortController, itemId: string, shopId: string, dateFrom?: string, dateTo?: string, pageIndex?: number, pageSize?: number, sort?: string) =>{
    try {
        const params = {
            shopId,
            pageIndex,
            pageSize,
            sort,
            dateFrom,
            dateTo
        }

        const response: AxiosResponse<EntityListResponseItemAvailabilityAdjustmentViewSummary,any> = await axios.get(`api/items/${itemId}/availability-adjustments`, {
            params,
            signal: controller.signal
        })
        
        return response.data

    } catch (error:any) {
        checkSessionExpired(error)
        throw error
    }
}

const getItemBusinessConditions = async(itemId: string) =>{
    try{
        const response: AxiosResponse<EntitySingleResponseListItemBusinessCondition,any> = await axios.get(`api/items/${itemId}/business-conditions`)
        return response.data
    } catch(error:any) {
        checkSessionExpired(error)
        throw error
    }
}

const getItemWarehousesMovements = async(controller: AbortController, itemId: string, dateFrom?: string, dateTo?: string, pageIndex?: number, pageSize?: number, sort?: string) =>{
    try {
        const params = {
            pageIndex,
            pageSize,
            sort,
            dateFrom,
            dateTo
        }

        const response: AxiosResponse<EntityListResponseWarehouseMovementViewSummary,any> = await axios.get(`api/items/${itemId}/warehouses-movements`, {
            params,
            signal: controller.signal
        })
        
        return response.data

    } catch (error:any) {
        checkSessionExpired(error)
        throw error
    }
}





export { getMetadata, getItemsFromCodeList, getItemBySku, getItemById, getItemsByIdList, getItemsbyParentSku, getItems, analyzeItems, patchItems, addImageToItems, getItemMetadata, getItemAvailabilityAdjustments, getItemBusinessConditions, getItemWarehousesMovements }