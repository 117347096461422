import { checkSessionExpired } from "@api/Utils"
import { EntityListResponseQueueEntryObject, EntitySingleResponseQueueEntryObject, QueueList } from "cs.node.utils/csapi/provisioning"
import { QueueEntryObjectStatusEnum } from "@utils/QueueUtils"
import axios, { AxiosResponse } from "axios"

type queuesEntriesByStatusesAndExternalKeyPayload = {
    queueId: string,
    externalKey?: string,
    pageIndex?: number,
    pageSize?: number,
    sort?: string,
    status?: QueueEntryObjectStatusEnum[]
}

const getQueuesEntriesByStatusesAndExternalKey = async (payload: queuesEntriesByStatusesAndExternalKeyPayload) =>{
    const { queueId, externalKey, pageIndex, pageSize, sort, status } = payload
    try {
        const response:AxiosResponse<EntityListResponseQueueEntryObject,any> = await axios.get(`api/queues/${queueId}/listByStatusesAndExternalKey`, {
            params: {
                externalKey,
                pageIndex,
                pageSize,
                sort,
                status
            }
        })
        console.log(response)
        return response.data
    } catch(error:any) {
        if(error.response?.status === 404) {
            return undefined
        }
        
        checkSessionExpired(error)
        throw error
    }
}

type updateQueueEntryStatusPayload = {
    queueId: string,
    entryId: string,
    newStatus: QueueEntryObjectStatusEnum
}

const updateQueueEntryStatus = async(payload: updateQueueEntryStatusPayload)=> {
    const { queueId, entryId, newStatus } = payload
    try {
        const response:AxiosResponse<EntityListResponseQueueEntryObject,any> = await axios.put(`api/queues/${queueId}/${entryId}/status`, {newStatus})
        console.log(response)
        return response.data
    } catch(error: any) {
        checkSessionExpired(error)
        throw error
    }
}


const getQueues = async (): Promise<QueueList> =>{
    try {
        const response:AxiosResponse<QueueList,any> = await axios.get('api/queues')
        return response.data
    } catch(error: any) {
        checkSessionExpired(error)
        throw error
    }
}

type getQueueEntryPayload = {
    queueId: string,
    entryId: string
}

const getQueue = async(payload: getQueueEntryPayload)=> {
    const { queueId, entryId } = payload
    try {
        const response:AxiosResponse<EntitySingleResponseQueueEntryObject,any> = await axios.get(`api/queues/${queueId}/${entryId}`)
        console.log(response)
        return response.data
    } catch(error: any) {
        checkSessionExpired(error)
        throw error
    }
}


export { getQueuesEntriesByStatusesAndExternalKey, updateQueueEntryStatus, getQueues, getQueue }